import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { Link } from "gatsby"
import ArrowRight from "../components/svg/arrowRight"

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
      <section role="main" className="sub-hero bg">
        <div className="container">
            <div className="row flex flex-jc">
                <div className="sub-hero-content text-center centred-div">
                    <h1 className="h1">
                        Error: 404
                    </h1>
                    <p className="white">Sorry, the page you are looking for doesn't exist.</p>
                    <div className="btn-row">
                      <Link to="/startup-developers/" className="btn">
                        <div className="arrow-circle">
                          <ArrowRight />
                        </div>
                        Home page
                      </Link>
                      <Link to="/contact/" className="btn">
                        <div className="arrow-circle">
                          <ArrowRight />
                        </div>
                        Contact us
                      </Link>
                    </div>
                </div>
            </div>
        </div>
      </section>
  </Layout>
)

export default NotFoundPage
